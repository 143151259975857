<template>
  <div>
    <h2>Correlation Analysis</h2>
    <div class="card">
      <div class="card-body">
        <h3 class="card-title">Daily Correlation</h3>
        <div class="row justify-content-between">
          <div class="col-md-4">
            <select v-model="show_id" class="form-control" @change="getGraph()" v-if="correlation_type == 'daily'">
              <option v-for="(option, i) in daily" :key="`daily-${i}`" :value="option.id">{{ option.date }} - {{ option.diff }}</option>
            </select>
            <select v-model="show_id" class="form-control" @change="getGraph()" v-if="correlation_type == '1-hour'">
              <option v-for="(option, i) in hour_1" :key="`daily-${i}`" :value="option.id">{{ option.date }} - {{ option.diff }}</option>
            </select>
            <select v-model="show_id" class="form-control" @change="getGraph()" v-if="correlation_type == '4-hour'">
              <option v-for="(option, i) in hour_4" :key="`daily-${i}`" :value="option.id">{{ option.date }} - {{ option.diff }}</option>
            </select>
            <select v-model="show_id" class="form-control" @change="getGraph()" v-if="correlation_type == 'weekly'">
              <option v-for="(option, i) in weekly" :key="`daily-${i}`" :value="option.id">{{ option.date }} - {{ option.diff }}</option>
            </select>
            <select v-model="show_id" class="form-control" @change="getGraph()" v-if="correlation_type == 'monthly'">
              <option v-for="(option, i) in monthly" :key="`daily-${i}`" :value="option.id">{{ option.date }} - {{ option.diff }}</option>
            </select>
          </div>
          <div class="col-md-4">
            <select v-model="correlation_type" class="form-control">
              <option value="1-hour">1 Hour</option>
              <option value="4-hour">4 Hour</option>
              <option value="daily">Daily</option>
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
        </div>
        <template v-if="loaded">
          <div class="row justify-content-center">
            <div class="col-md-8">
              <chart :data="graph_data" type="bar" :height="`200px`" />
            </div>
          </div>

          <div class="row mt-5">
            <div class="col-md-4">
              <h3>Strong</h3>
              <ul>
                <li v-for="(strong, i) in analysis.strong" :key="`strong-${i}`">{{ strong }}</li>
              </ul>
            </div>
            <div class="col-md-4">
              <h3>Neutral</h3>
              <ul>
                <li v-for="(neutral, i) in analysis.neutral" :key="`neutral-${i}`">{{ neutral }}</li>
              </ul>
            </div>
            <div class="col-md-4">
              <h3>Weak</h3>
              <ul>
                <li v-for="(weak, i) in analysis.weak" :key="`weak-${i}`">{{ weak }}</li>
              </ul>
            </div>
          </div>
        </template>
        <div v-if="! loaded" class="text-center py-5">
          <img src="/loader.gif" alt="logo" style="height: 100px">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from '@/components/charts/Chart'

export default {
  components: {
    Chart
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      show_id: 0,
      hour_1: [],
      hour_4: [],
      daily: [],
      weekly: [],
      monthly: [],
      loaded: false,
      graph_data: {},
      correlation_type: 'daily',
      analysis: {}
    }
  },

  methods: {
    fetch() {
      this.$loader.start()

      this.$axios.get('/api/v1/moderator/correlation').then(response => {
        this.hour_1 = response.data.hour_1
        this.hour_4 = response.data.hour_4
        this.daily = response.data.daily
        this.weekly = response.data.weekly
        this.monthly = response.data.monthly

        if(this.daily.length > 0) {
          this.show_id = response.data.daily[0].id
          this.correlation_type = 'daily'
        }
        else if(this.hour_4.length > 0) {
          this.show_id = response.data.hour[0].id
          this.correlation_type = '4-hour'
        }
        else if(this.hour_1.length > 0) {
          this.show_id = response.data.hour[0].id
          this.correlation_type = '1-hour'
        }
        else if(this.weekly.length > 0) {
          this.show_id = response.data.hour[0].id
          this.correlation_type = 'weekly'
        }
        else if(this.monthly.length > 0) {
          this.show_id = response.data.hour[0].id
          this.correlation_type = 'monthly'
        }

        this.$loader.stop()
      })
    },

    getGraph() {
      this.loaded = false

      this.$axios.get(`/api/v1/moderator/correlation/${this.show_id}`).then(response => {
        this.graph_data = response.data.graph_data
        this.analysis = response.data.analysis
        this.loaded = true
      })
    }
  },

  watch: {
    show_id() {
      this.getGraph()
    },

    correlation_type(newval) {
      this.show_id = this[newval][0].id
    }
  }
}
</script>